import _ from 'lodash'
import i18n from '@/plugins/i18n'
//import { contentWithBorder, field, safeText } from '@/services/pdf-utils'
//import locationService from '@/services/location'
//import { current, format } from '@/services/datetime'
import formTemplate from './template'
import { materialRow } from './material'
import { chainOfCustodyRow } from './custody'
import { finalDispositionAction, witnessToDestruction } from './disposition'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

/*
* Get PDF as a buffer
*/
export const exportFormToBuffer = async (form, options) => {
  const pdf = await exportForm(form, options)
  return pdf.getBuffer()
}

/*
 * Download pdf
 */
export const exportFormAndDownload = async (form, options) => {
  const pdf = await exportForm(form, options)
  const filename = `${form.name}.pdf`
  pdf.download(filename)
}

/*
 * Open pdf
 */
export const exportFormAndOpen = async (form, options) => {
  const pdf = await exportForm(form, options)
  pdf.open()
}

export const exportForm = async (form, options) =>  {
  const context = {
    progress: options.onProgress || (() => {}),
    complete: options.onComplete || (() => {})
  }

  const doc = await exportFormDocumentDefinition(
    form,
    options,
    context
  )

  context.progress(i18n.t('export.pdf.progress.creating'))
  const pdf = pdfMake.createPdf(doc)

  context.complete(i18n.t('export.pdf.progress.complete'))

  return pdf
}


/*
 * Export form document definition
 */
export const exportFormDocumentDefinition = async (
  form,
  options,
  context
) => {
  context.progress(i18n.t('export.pdf.progress.starting'))

  //collection = await validateCollection(collection)
  return await buildDocumentDefinition(form, options, context)
}

/*
 * Build the document definition for a collection
 */
export const buildDocumentDefinition = async (form, options, context) => {
  context.progress(i18n.t('export.pdf.progress.building'))

  const doc = _.cloneDeep(formTemplate)

  doc.images['appLogo'] = options.logoUrl

  //
  // Info
  //
  doc.info = {
    title: form.name,
    creator: options.applicationName,
    producer: options.applicationName,
  }
  context.progress(i18n.t('export.pdf.progress.info'))

  context.progress(i18n.t('export.pdf.progress.headerFooter'))

  //
  // Add Material
  //
  const materialSection = doc.content.find(s => s.section === 'material')
  for(let i = 0; i < form.content.material.length; i++) {
    const m = materialRow(form.content.material[i])
    materialSection.table.body.push(m)
    materialSection.table.heights.push(25)
  }

  //
  // Chain of Custody
  //
  const chainOfCustodySection = doc.content.find(s => s.section === 'chainOfCustody')
  for(let i = 0; i < 6; i++) {
    const m = chainOfCustodyRow(form)
    chainOfCustodySection.table.body.push(...m)
    chainOfCustodySection.table.heights.push(...[25,25])
  }

  //
  // Disposition
  //
  doc.content.push(...finalDispositionAction());
  doc.content.push(...witnessToDestruction());

  context.progress(i18n.t('export.pdf.progress.documentDefinitionComplete'))

  console.log('doc and template', { form, doc, formTemplate })
  return doc
}
